import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Spinner, Alert } from 'react-bootstrap';
import YELLOW_CARD from "../resources/images/yc.png";
import RED_CARD from "../resources/images/rc.png";
import PENALTY_7_METERS from "../resources/images/sm.png";
import PENALTY_2_MINUTES from "../resources/images/tm.png";
import TIMEOUT from "../resources/images/to.png";
import START from "../resources/images/st.png";
import GOAL from "../resources/images/go.png";
import { useTranslation } from "react-i18next";

// Function to map action types to icon paths (can be URLs or local paths)
const getIconForAction = (actionType) => {
  switch (actionType) {
    case 'YELLOW_CARD':
      return YELLOW_CARD;
    case 'RED_CARD':
      return RED_CARD;
    case 'PENALTY_7_METERS':
      return PENALTY_7_METERS;
    case 'PENALTY_2_MINUTES':
      return PENALTY_2_MINUTES;
    case 'TIMEOUT':
      return TIMEOUT;
    case 'START':
      return START;
    case 'STOP':
      return START;
    case 'GOAL':
      return GOAL;
    default:
      return null;
  }
};

// Function to determine the color based on the action type
const getColorForAction = (actionType) => {
  if (actionType.includes('YELLOW') || actionType.includes('MINUTES')) {
    return '#FFD700'; // Yellow
  } else if (actionType.includes('RED') || actionType.includes('STOP')) {
    return '#FF4500'; // Red
  } else if (actionType.includes('GOAL') || actionType.includes('START')) {
    return '#32CD32'; // Green
  } else {
    return '#808080'; // Default Grey
  }
};

const MatchDetailsPage = () => {
  const { matchId } = useParams();
  const [matchDetails, setMatchDetails] = useState(null);
  const [matchProtocols, setMatchProtocols] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  // Helper function to sort protocols by realTimestamp
  const sortProtocolsByTimestamp = (protocols) => {
    return [...protocols].sort((a, b) => new Date(b.realTimestamp) - new Date(a.realTimestamp));
  };

  useEffect(() => {
    const fetchMatchData = async () => {
      setLoading(true);
      try {
        const matchDetailsResponse = await axios.get(`/api/matches/${matchId}`);
        const matchProtocolsResponse = await axios.get(`/api/matches/${matchId}/protocol`);

        // Sort protocols by realTimestamp in descending order
        const sortedProtocols = sortProtocolsByTimestamp(matchProtocolsResponse.data);

        setMatchDetails(matchDetailsResponse.data);
        setMatchProtocols(sortedProtocols);
        setLoading(false);
      } catch (err) {
        setError(t('error.match.details'));
        setLoading(false);
      }
    };
    fetchMatchData();

    // Poll the protocol data every 10 seconds
    const intervalId = setInterval(async () => {
      try {
        const matchProtocolsResponse = await axios.get(`/api/matches/${matchId}/protocol`);
        const sortedProtocols = sortProtocolsByTimestamp(matchProtocolsResponse.data);
        setMatchProtocols(sortedProtocols);

        const matchDetailsResponse = await axios.get(`/api/matches/${matchId}`);
        setMatchDetails(matchDetailsResponse.data);
      } catch (err) {
        setError(t('error.match.protocol'));
      }
    }, 10000);

    // Progress bar logic for visual indication
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress + 10) % 100);
    }, 1000);

    // Cleanup intervals on component unmount
    return () => {
      clearInterval(intervalId);
      clearInterval(progressInterval);
    };
  }, [matchId]);

  if (loading)
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  if (error) return <Alert variant="danger">{error}</Alert>;
  if (!matchDetails) return <Alert variant="warning">{t("alert.match.details")}</Alert>;

  return (
    <Container className="mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      {/* Visual Indicator for refresh progress */}
      <div className="h-1 rounded-lg px-1">
        <div
          className="bg-orange-300 h-full rounded-lg"
          style={{ width: `${progress}%`, transition: 'width 1s linear' }}
        />
      </div>
      {/* Team Names and Score */}
      <div className="match-card border border-gray-300 px-0 rounded-lg mb-8 flex items-center bg-white relative overflow-hidden">
        {/* Background blur effect */}
        <div
          className="absolute inset-0 bg-gray-100 opacity-50 rounded-lg blur-sm"
          style={{ filter: 'blur(8px)' }}
        />
        {/* First Column: Team A Name */}
        <div
          className="team-a font-semibold text-sm border-r-8"
          style={{
            borderRightColor: matchDetails.teamA.color1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            flex: '1 1 0',
            textAlign: 'right',
            padding: '8px 16px',
            position: 'relative',
            zIndex: 1,
          }}
        >
          {matchDetails.teamA.name}
        </div>

        {/* Second Column: Score */}
        <div
          className="score font-semibold mx-0 text-sm"
          style={{ width: '70px', textAlign: 'center', position: 'relative', zIndex: 1 }}
        >
          {matchDetails.scoreA ?? 0} - {matchDetails.scoreB ?? 0}
        </div>

        {/* Third Column: Team B Name */}
        <div
          className="team-b font-semibold text-sm border-l-8"
          style={{
            borderLeftColor: matchDetails.teamB.color1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            flex: '1 1 0',
            textAlign: 'left',
            padding: '8px 16px',
            position: 'relative',
            zIndex: 1,
          }}
        >
          {matchDetails.teamB.name}
        </div>
      </div>

      {/* Match Actions (Protocol) */}
      {matchProtocols.length > 0 ? (
        <div className="p-0">
          {matchProtocols.map((protocol, index) => {
            const isTeamA = protocol.teamName === matchDetails.teamA.name;
            const actionIcon = getIconForAction(protocol.action);
            const iconColor = getColorForAction(protocol.action);

            // Insert an empty card if a "STOP" action is followed by a "START" action
            const isBreak = protocol.action === "START" &&
              matchProtocols[index + 1]?.action === "STOP";

            return (
              <React.Fragment key={index}>
                {/* Render the protocol card */}
                <div
                  className="match-card border border-gray-300 px-2 my-2 py-1 rounded-lg flex items-center bg-white relative overflow-hidden"
                >             
                {/* Team A Timer */}
                  <div
                    className="font-semibold text-xs text-gray-500"
                    style={{ width: '35px', textAlign: 'left', position: 'relative', zIndex: 1 }}
                  >
                    {protocol.timestamp}
                  </div>

                  {/* Team A Player Info */}
                  <div
                    className="text-xs text-gray-700 border-r-4 pr-2"
                    style={{
                      borderRightColor: matchDetails.teamA.color1,
                      flex: '1 1 0',
                      textAlign: 'right',
                      position: 'relative',
                      zIndex: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {protocol.action === "START"
                      ? t("actions.start") + " " + protocol.period
                      : protocol.action === "STOP"
                        ? t("actions.stop") + " " + protocol.period
                        : protocol.action === "TIMEOUT" && isTeamA
                          ? t("actions.timeout")
                          : protocol.action === "PENALTY_7_METERS" && isTeamA
                            ? t("actions.sevenMeters")
                            : isTeamA && protocol.playerName !== null
                              ? `#${protocol.playerNumber} ${protocol.playerName}`
                              : protocol.fallbackNumber !== null && protocol.fallbackNumber !== 0 && isTeamA
                                ? `#${protocol.fallbackNumber}`
                                : null}
                  </div>

                  <div className="d-flex px-1" style={{ position: 'relative', zIndex: 1 }}>
                    {actionIcon && (
                      <img
                        src={actionIcon}
                        alt={protocol.action}
                        title={t(protocol.action)}
                        style={{
                          height: '20px',
                          width: '20px',
                          filter: `drop-shadow(1px 1px 1px ${iconColor})`,
                        }}
                      />
                    )}
                  </div>

                  {/* Team B Player Info */}
                  <div
                    className="text-xs text-gray-700 border-l-4 pl-2"
                    style={{
                      borderLeftColor: matchDetails.teamB.color1,
                      flex: '1 1 0',
                      textAlign: 'left',
                      position: 'relative',
                      zIndex: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {protocol.action === "START"
                      ? t("actions.start") + " " + protocol.period
                      : protocol.action === "STOP"
                        ? t("actions.stop") + " " + protocol.period
                        : protocol.action === "TIMEOUT" && !isTeamA
                          ? t("actions.timeout")
                          : protocol.action === "PENALTY_7_METERS" && !isTeamA
                            ? t("actions.sevenMeters")
                            : !isTeamA && protocol.playerName !== null
                              ? `#${protocol.playerNumber} ${protocol.playerName}`
                              : protocol.fallbackNumber !== null && protocol.fallbackNumber !== 0 && !isTeamA
                                ? `#${protocol.fallbackNumber}`
                                : null}
                  </div>

                  {/* Team B Timer */}
                  <div
                    className="font-semibold text-xs text-gray-500"
                    style={{ width: '35px', textAlign: 'right', position: 'relative', zIndex: 1 }}
                  >
                    {protocol.timestamp}
                  </div>
                </div>

                {/* Render empty card for break between STOP and START */}
                {isBreak && (
                  <div
                    className="match-card border border-gray-300 my-2 py-2 rounded-lg flex items-center justify-center"
                  >
                    <span className="text-md text-gray-500 font-medium">{t("actions.break")}</span>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        <Alert
          className="font-semibold text-xs text-gray-500 mx-auto"
          variant="info"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '-50px',
            paddingTop: '100px',
          }}
        >
          {t("alert.no.actions")}
        </Alert>
      )}

    </Container>
  );
};

export default MatchDetailsPage;
