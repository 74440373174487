import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import { useTranslation } from "react-i18next";
import { Table, Th, Td, Tr } from "../components/Table";
import Button from "../components/Button";

function UsersPage() {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchUsers();
  }, [token]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get("/api/users", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Failed to fetch users", error);
      setErrorMessage(t("Error fetching users."));
    }
  };

  const renderUsersTable = () => (
    <div className="overflow-auto">
      <Table>
        <thead>
          <Tr>
            <Th>{t("ID")}</Th>
            <Th>{t("Name")}</Th>
            <Th>{t("Email")}</Th>
            <Th>{t("Status")}</Th>
          </Tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <Tr key={user.id}>
              <Td>{user.id}</Td>
              <Td>{user.name}</Td>
              <Td>{user.email}</Td>
              <Td>{user.status === "ACTIVATED" ? t("Active") : t("Inactive")}</Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  return (
    <div className="container mx-auto my-8 p-4 bg-white rounded-md shadow-lg">
      <h1 className="text-2xl mb-4">{t("Users")}</h1>
      {errorMessage && (
        <p className="text-red-700 text-sm border border-red-600 p-2">
          {errorMessage}
        </p>
      )}
      {renderUsersTable()}
      <div className="mt-4">
        <Button color="orange" onClick={() => window.location.href = "/create-user"}>
          {t("Create New User")}
        </Button>
      </div>
    </div>
  );
}

export default UsersPage;
