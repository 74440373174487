import React from 'react';

function PrivacyPolicyPage() {
  return (
    <div className="container mx-auto my-8 p-8 bg-white rounded-md shadow-lg text-sm">
      <h1 className="text-2xl font-bold text-center mb-8">Privacy Policy</h1>

      <p className="mb-4"><strong>Effective Date:</strong> 10.10.2024</p>

      <p className="mb-4">Thank you for using Xantolo Handball ("App"). We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use the App, in compliance with applicable laws and regulations.</p>

      <h2 className="text-xl font-semibold mt-6 mb-4">1. Information We Collect</h2>
      <p className="mb-4">We strive to limit the collection of personal information to what is necessary for providing the services within the Xantolo Handball App. The types of information we collect include:</p>

      <h3 className="text-lg font-semibold mt-4 mb-2">A. Information You Provide:</h3>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>Account Information:</strong> When match officials register and log in, we collect information such as your username, email address, and password.</li>
        <li><strong>Profile Information:</strong> Optional information such as profile pictures or any additional details provided in your profile.</li>
        <li><strong>Communication:</strong> If you contact us via email or through the App, we may collect and store your contact details and any other information you provide.</li>
      </ul>

      <h3 className="text-lg font-semibold mt-4 mb-2">B. Automatically Collected Information:</h3>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>Device Information:</strong> When using the App, we collect information about the device you use, including hardware model, operating system version, unique device identifiers, IP address, and mobile network information.</li>
        <li><strong>Usage Information:</strong> We may collect data about how you use the App, including pages visited, actions taken, and interaction times, to improve performance and provide better services.</li>
        <li><strong>Log Data:</strong> The App automatically collects log data in case of crashes or errors, including your device’s IP address, the time and date of usage, and other system-related data.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-4">2. Use of Information</h2>
      <p className="mb-4">We may use the information we collect for the following purposes:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>To provide, maintain, and improve the functionality of the Xantolo Handball App.</li>
        <li>To enable match officials to log in and manage game protocols.</li>
        <li>To communicate with you regarding important updates, notifications, and customer support.</li>
        <li>To monitor and analyze usage patterns and trends to enhance your user experience.</li>
        <li>To ensure the security and safety of your information and our platform.</li>
        <li>To comply with legal obligations and to enforce our terms and conditions.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-4">3. Information Sharing and Disclosure</h2>
      <p className="mb-4">We do not sell or share your personal information with third parties for marketing purposes. However, we may share your information under the following circumstances:</p>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>With Service Providers:</strong> We may share information with third-party service providers who assist us with the operation of the App, such as cloud storage services, but they are required to protect your information.</li>
        <li><strong>For Legal Reasons:</strong> We may disclose your information if we are required to do so by law, such as in response to a court order, subpoena, or governmental request.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-4">4. Data Security</h2>
      <p className="mb-4">We use industry-standard measures to protect your personal information, including encryption, secure networks, and access control measures. While we strive to protect your data, please be aware that no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee the absolute security of your data.</p>

      <h2 className="text-xl font-semibold mt-6 mb-4">5. Retention of Information</h2>
      <p className="mb-4">We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected, or as required by law. Once the information is no longer needed, we will securely delete or anonymize it.</p>

      <h2 className="text-xl font-semibold mt-6 mb-4">6. Children’s Privacy</h2>
      <p className="mb-4">Xantolo Handball is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us immediately, and we will take steps to delete that information.</p>

      <h2 className="text-xl font-semibold mt-6 mb-4">7. International Transfers</h2>
      <p className="mb-4">Your personal information may be stored and processed in any country where we have facilities or where we engage third-party service providers. By using the App, you consent to the transfer of information to countries outside your country of residence, which may have different data protection rules.</p>

      <h2 className="text-xl font-semibold mt-6 mb-4">8. Your Rights</h2>
      <p className="mb-4">Depending on your location, you may have the right to:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>Access, correct, or delete your personal information.</li>
        <li>Object to or restrict the processing of your personal information.</li>
        <li>Withdraw consent at any time, where we rely on your consent to process your data.</li>
      </ul>
      <p className="mb-4">To exercise any of these rights, please contact us at <a href="mailto:info@xantolohandball.com" className="text-blue-500 underline">info@xantolohandball.com</a>.</p>

      <h2 className="text-xl font-semibold mt-6 mb-4">9. Changes to This Privacy Policy</h2>
      <p className="mb-4">We may update this Privacy Policy from time to time to reflect changes in our practices, technology, or legal requirements. When we make changes, we will notify you by updating the effective date at the top of the policy. We encourage you to review this Privacy Policy periodically for any updates.</p>

      <h2 className="text-xl font-semibold mt-6 mb-4">10. Contact Us</h2>
      <p><strong>Xantolo Handball</strong><br />
      Email: <a href="mailto:info@xantolohandball.com" className="text-blue-500 underline">info@xantolohandball.com</a><br /></p>
    </div>
  );
}

export default PrivacyPolicyPage;
