import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from "./AuthContext";
import MatchCard from '../components/MatchCard';
import { Container, Row, Col, Spinner, Alert, Tabs, Tab, Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const MatchesPage = () => {
  const [matches, setMatches] = useState([]);
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState("2024-11-01");
  const [dates, setDates] = useState(["2024-11-01", "2024-11-02", "2024-11-03"]);
  
  const [groupByField, setGroupByField] = useState(false); // New state for grouping toggle
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  
  const { isLoggedIn } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const { t } = useTranslation();
  
  useEffect(() => {
    fetchMatches(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    filterMatches(searchQuery, categoryFilter, genderFilter);
  }, [matches, searchQuery, categoryFilter, genderFilter]);

  const fetchMatches = async (date) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/api/matches?date=${date}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMatches(response.data);
      setFilteredMatches(response.data);
      setLoading(false);
    } catch (error) {
      setError(t('error.match.details'));
      setLoading(false);
    }
  };

  const getWeekdayName = (date) => {
    if (date === "2024-11-01") {
      return "Friday";
    } else if (date === "2024-11-02") {
      return "Saturday";
    } else if (date === "2024-11-03") {
      return "Sunday";
    }
  };

  const filterMatches = (query, category, gender) => {
    const filtered = matches.filter(
      (match) =>
        (match.teamA.toLowerCase().includes(query) ||
         match.teamB.toLowerCase().includes(query)) &&
        (!category || match.type === category) &&
        (!gender || match.gender === gender)
    );
    setFilteredMatches(filtered);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleCategoryChange = (e) => {
    setCategoryFilter(e.target.value);
  };

  const handleGenderChange = (e) => {
    setGenderFilter(e.target.value);
  };

 // Group by Type + Gender + Group with sorting by timestamp
const groupByTypeGenderAndGroup = (matches) => {
  return matches.reduce((grouped, match) => {
    const type = match.type || 'Unknown Category';
    const gender = match.gender || 'Unknown Gender';
    const groupName = match.groupName || 'noGroup';
    const typeGenderKey = `${type} - ${gender}`;

    if (!grouped[typeGenderKey]) {
      grouped[typeGenderKey] = {};
    }

    if (!grouped[typeGenderKey][groupName]) {
      grouped[typeGenderKey][groupName] = [];
    }

    grouped[typeGenderKey][groupName].push(match);
    return grouped;
  }, {});
};

// Group by Field with sorting by timestamp
const groupByFieldFunc = (matches) => {
  return matches.reduce((grouped, match) => {
    const field = match.field || 'Unknown Field';
    if (!grouped[field]) {
      grouped[field] = [];
    }
    grouped[field].push(match);
    return grouped;
  }, {});
};

// Sort the grouped matches by timestamp
const sortGroupedMatches = (groupedMatches) => {
  Object.keys(groupedMatches).forEach((groupKey) => {
    if (Array.isArray(groupedMatches[groupKey])) {
      // Sort array if directly grouped by field
      groupedMatches[groupKey].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    } else {
      // Sort each subgroup if grouped by type, gender, and group
      Object.keys(groupedMatches[groupKey]).forEach((subgroupKey) => {
        groupedMatches[groupKey][subgroupKey].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      });
    }
  });
};

// Sort and display grouped matches based on the grouping option
const groupedMatches = groupByField ? groupByFieldFunc(filteredMatches) : groupByTypeGenderAndGroup(filteredMatches);
sortGroupedMatches(groupedMatches);

  if (loading) return <Spinner animation="border" role="status" className="text-center m-5"><span className="sr-only">Loading...</span></Spinner>;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <Container className="mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl mb-4">{t('Scheduled Matches')}</h1>

      {/* Date Tabs */}
      <Tabs
        activeKey={selectedDate}
        onSelect={(date) => setSelectedDate(date)}
        className="custom-tabs mb-4"
        justify
      >
        {dates.map((date) => (
          <Tab className="bg-header-background" eventKey={date} title={t(getWeekdayName(date))} key={date} />
        ))}
      </Tabs>

      {/* Filters and Switch in one row */}
      <div className="flex gap-2 mb-4 items-center">
        <Form.Control
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder={t('Search by team name')}
          className="border border-gray-300 w-1/4 p-2 text-xs"
        />

        <Form.Select
          value={categoryFilter}
          onChange={handleCategoryChange}
          className="border border-gray-300 w-1/4 p-2 text-xs"
        >
          <option value="">{t('All Categories')}</option>
          <option value="NEW_TALENT">{t('New talents')}</option>
          <option value="CHILDREN">{t('Children')}</option>
          <option value="SCHOOL">{t('School')}</option>
          <option value="CADET">{t('Cadet')}</option>
          <option value="YOUTH">{t('Youth')}</option>
          <option value="ADULT">{t('Adult')}</option>
          <option value="MASTER">{t('Master')}</option>
        </Form.Select>

        <Form.Select
          value={genderFilter}
          onChange={handleGenderChange}
          className="border border-gray-300 w-1/4 p-2 text-xs"
        >
          <option value="">{t('All Genders')}</option>
          <option value="MAN">{t('man')}</option>
          <option value="WOMAN">{t('woman')}</option>
          <option value="MIXED">{t('mixed')}</option>
        </Form.Select>

        {/* Group by Field Switch */}
        <Form.Check
          type="switch"
          id="grouping-switch"
          label={t('Court schedule')}
          checked={groupByField}
          onChange={() => setGroupByField(!groupByField)}
          className="p-2 text-xs"
        />
      </div>

      {/* Render grouped matches */}
      {Object.keys(groupedMatches).map((groupKey) => (
        <div key={groupKey} className="match-group mb-4">
          <h2 className="text-sm font-medium mt-4">{t(groupKey)}</h2>

          {Array.isArray(groupedMatches[groupKey]) ? (
            <Row>
              {groupedMatches[groupKey].map((match) => (
                <Col md={6} lg={4} key={match.id} className="mb-4">
                  <MatchCard match={match} isTeamGroup={groupByField} />
                </Col>
              ))}
            </Row>
          ) : (
            Object.keys(groupedMatches[groupKey]).map((subgroupKey) => (
              <div key={subgroupKey} className="subgroup mb-4">
                {subgroupKey !== "noGroup" && (
                  <h3 className="text-xs font-semibold text-gray-500 mt-2 mb-2">
                    { 
                      subgroupKey.includes('Finals ') 
                        ? `${t('Finals')} ${subgroupKey.split('Finals ')[1]}` 
                        : subgroupKey === 'Finals'
                          ? t('Finals')
                          : `${t('Group')} ${subgroupKey}`
                    }
                  </h3>
                )}
                <Row>
                  {groupedMatches[groupKey][subgroupKey].map((match) => (
                    <Col md={6} lg={4} key={match.id} className="mb-4">
                      <MatchCard match={match} isTeamGroup={groupByField} />
                    </Col>
                  ))}
                </Row>
              </div>
            ))
          )}
        </div>
      ))}
    </Container>
  );
};

export default MatchesPage;
