import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const MatchCard = ({ match, isTeamGroup }) => {
  const date = new Date(match.timestamp);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const formattedTime = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
  });

  const handleMatchClick = () => {
    console.log(match);
    navigate(`/match/${match.id}`);
  };

  const getCategoryStyle = (gender) => {
    if (gender === 'MAN') {
      return { backgroundColor: '#ADD8E6' }; // Light blue for male
    } else if (gender === 'WOMAN') {
      return { backgroundColor: '#FFB6C1' }; // Light pink for female
    } else {
      return { backgroundColor: '#D3D3D3' }; // Grey for mixed or none
    }
  };

  return (
    <div
      className="match-card border border-gray-300 pl-2 my-2 rounded-lg flex items-center bg-white relative overflow-hidden"
      onClick={handleMatchClick}
      style={{ cursor: 'pointer' }}
    >
      {/* Background blur effect */}
      <div
        className="absolute inset-0 bg-gray-100 opacity-50 rounded-lg blur-sm"
        style={{ filter: 'blur(8px)' }}
      />

      {/* First Column: Timestamp */}
      <div
        className="match-time font-semibold text-[0.65rem] lg:text-md text-gray-600"
        style={{ width: '60px', textAlign: 'left', position: 'relative', zIndex: 1 }}
      >
        {formattedTime}
      </div>

      {/* Second Column: Team A */}
      <div
        className="team-a text-[0.65rem] md:text-md font-semibold border-r-4"
        style={{
          borderRightColor: match.colorA,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          flex: '1 1 0',
          textAlign: 'right',
          padding: '4px 8px',
          position: 'relative',
          zIndex: 1,
        }}
      >
        {match.teamA}
      </div>

      {/* Third Column: "vs" text */}
      <div
        className="score text-[0.65rem] md:text-md text-gray-700 font-semibold mx-0"
        style={{ width: '40px', textAlign: 'center', position: 'relative', zIndex: 1 }}
      >
        {match.scoreA} - {match.scoreB}
      </div>

      {/* Fourth Column: Team B */}
      <div
        className="team-b text-[0.65rem] md:text-md font-semibold border-l-4"
        style={{
          borderLeftColor: match.colorB, 
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          flex: '1 1 0',
          textAlign: 'left',
          padding: '4px 8px',
          position: 'relative',
          zIndex: 1,
        }}
      >
        {match.teamB}
      </div>

      {/* Fifth Column: Field or Category based on isTeamGroup */}
      {isTeamGroup ? (
        <div
          className="match-category text-[0.65rem] "
          style={{
            backgroundColor: `${getCategoryStyle(match.gender).backgroundColor}50`, // Correctly accessing backgroundColor
            borderRadius: '8px',
            overflow: 'hidden',
            width: '70px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textAlign: 'center',

            padding: '4px 8px',
            position: 'relative',
            zIndex: 1,
          }}
        >
          {t(match.type).substring(0, t(match.type).indexOf(" ("))}
        </div>
      ) : (
        <div
          className="match-type text-[0.65rem] md:text-md text-gray-500 pr-4"
          style={{
            overflow: 'hidden',
            width: '60px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textAlign: 'center',

            padding: '4px 8px',
            position: 'relative',
            zIndex: 1,
          }}
        >
          {t(match.field)}
        </div>
      )}
    </div>
  );
};

export default MatchCard;
