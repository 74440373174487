import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from "./AuthContext";
import { Container, Spinner, Alert, Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Table, Th, Td, Tr } from "../components/Table";

const StandingsPage = () => {
  const [matches, setMatches] = useState([]);
  const [groupedStandings, setGroupedStandings] = useState({});
  const [filteredStandings, setFilteredStandings] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");

  const { isLoggedIn } = useContext(AuthContext);
  const { username } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const { t } = useTranslation();

  useEffect(() => {
    fetchMatches();
  }, []);

  const fetchMatches = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/api/matches`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMatches(response.data);
      processStandings(response.data);
      setLoading(false);
    } catch (error) {
      setError(t('error.match.details'));
      setLoading(false);
    }
  };

  const processStandings = (matches) => {
    const grouped = {};

    matches.forEach(match => {
      const type = match.type || 'Unknown Category';
      const gender = match.gender || 'Unknown Gender';
      const groupName = match.groupName || '';

      if (!grouped[type]) grouped[type] = {};
      if (!grouped[type][gender]) grouped[type][gender] = {};
      if (!grouped[type][gender][groupName]) {
        grouped[type][gender][groupName] = {
          teams: {},
          matches: [],
        };
      }

      const group = grouped[type][gender][groupName];
      group.matches.push(match);

      [match.teamA, match.teamB].forEach(teamName => {
        if (!group.teams[teamName]) {
          group.teams[teamName] = {
            teamName,
            teamColor: '',
            matchesPlayed: 0,
            wins: 0,
            draws: 0,
            losses: 0,
            goalsScored: 0,
            goalsConceded: 0,
            goalDifference: 0,
            points: 0,
          };
        }
      });

      const teamAStats = group.teams[match.teamA];
      const teamBStats = group.teams[match.teamB];

      if (match.scoreA > 0 || match.scoreB > 0) {
        teamAStats.matchesPlayed += 1;
        teamBStats.matchesPlayed += 1;
      }

      teamAStats.goalsScored += match.scoreA;
      teamAStats.goalsConceded += match.scoreB;

      teamBStats.goalsScored += match.scoreB;
      teamBStats.goalsConceded += match.scoreA;

      teamAStats.teamColor = match.colorA;
      teamBStats.teamColor = match.colorB;

      if (match.scoreA > match.scoreB) {
        teamAStats.wins += 1;
        teamBStats.losses += 1;
      } else if (match.scoreB > match.scoreA) {
        teamBStats.wins += 1;
        teamAStats.losses += 1;
      } else if (match.scoreA > 0 || match.scoreB > 0) {
        teamAStats.draws += 1;
        teamBStats.draws += 1;
      }

      [teamAStats, teamBStats].forEach(team => {
        team.goalDifference = team.goalsScored - team.goalsConceded;
        team.points = team.wins * 2 + team.draws;
      });
    });

    Object.keys(grouped).forEach(type => {
      Object.keys(grouped[type]).forEach(gender => {
        Object.keys(grouped[type][gender]).forEach(groupName => {
          const group = grouped[type][gender][groupName];
          group.teamStandings = Object.values(group.teams).sort((a, b) => {
            if (b.points !== a.points) return b.points - a.points;
            if (b.goalDifference !== a.goalDifference) return b.goalDifference - a.goalDifference;
            return b.goalsScored - a.goalsScored;
          });
        });
      });
    });

    setGroupedStandings(grouped);
    applyFilters(searchQuery, categoryFilter, grouped);
  };

  const applyFilters = (query, category, standings) => {
    const filtered = JSON.parse(JSON.stringify(standings));

    Object.keys(filtered).forEach(type => {
      if (category && type !== category) {
        delete filtered[type];
        return;
      }

      Object.keys(filtered[type] || {}).forEach(gender => {
        Object.keys(filtered[type][gender] || {}).forEach(groupName => {
          const group = filtered[type][gender][groupName];
          group.teamStandings = (group.teamStandings || []).filter(team =>
            team.teamName.toLowerCase().includes(query)
          );
        });
      });
    });

    setFilteredStandings(filtered);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    applyFilters(query, categoryFilter, groupedStandings);
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setCategoryFilter(category);
    applyFilters(searchQuery, category, groupedStandings);
  };

  if (loading) return <Spinner animation="border" role="status" className="text-center m-5"><span className="sr-only">Loading...</span></Spinner>;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <Container className="mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl mb-8">{t('home.results.title')}</h1>

      {/* Filters */}
      <div className="flex gap-2 mb-4 items-center">
        <Form.Select
          value={categoryFilter}
          onChange={handleCategoryChange}
          className="border border-gray-300 lg:w-1/4 w-1/2 p-2 text-xs"
        >
          <option value="">{t('All Categories')}</option>
          <option value="NEW_TALENT">{t('New talents')}</option>
          <option value="CHILDREN">{t('Children')}</option>
          <option value="SCHOOL">{t('School')}</option>
          <option value="CADET">{t('Cadet')}</option>
          <option value="YOUTH">{t('Youth')}</option>
          <option value="ADULT">{t('Adult')}</option>
          <option value="MASTER">{t('Master')}</option>
        </Form.Select>
      </div>

      {/* Render filtered standings */}
      {Object.keys(filteredStandings).map(type => {
        const category = filteredStandings[type];
        return (
          <div key={type} className="mb-6">
            <h2 className="text-xl font-semibold mb-6 border-b pb-2">{t(type)}</h2>
            <div className="flex flex-wrap -mx-2">
              {Object.keys(category || {}).map(gender => {
                const genderGroups = category[gender];
                return (
                  <div key={gender} className="w-full lg:w-1/2 px-2 mb-6">
                    <h3 className="text-md font-semibold mb-4 text-center">{t(gender)}</h3>
                    {Object.keys(genderGroups || {}).map(groupName => {
                      const group = genderGroups[groupName];
                      return (
                        <div key={groupName} className="mb-6">
                          <h4 className="text-sm font-medium mb-2 text-gray-500">
                          {groupName !== '' 
                            ? groupName === 'Finals' 
                                ? t('Finals') 
                                : groupName.includes('Finals ') 
                                    ? `${t('Finals')} ${groupName.split('Finals ')[1]}` 
                                    : `${t('Group')} ${groupName}` 
                            : t('Unique Group')}                          </h4>
                          <div className="overflow-x-auto">
                            <Table>
                              <thead>
                                <Tr>
                                  <Th>{t('Team')}</Th>
                                  <Th>{t('MP')}</Th>
                                  <Th>{t('W')}</Th>
                                  <Th>{t('D')}</Th>
                                  <Th>{t('L')}</Th>
                                  <Th>{t('GF')}</Th>
                                  <Th>{t('GA')}</Th>
                                  <Th>{t('GD')}</Th>
                                  <Th>{t('Pts')}</Th>
                                </Tr>
                              </thead>
                              <tbody>
                                {(group.teamStandings || []).map((team, index) => (
                                  <Tr key={team.teamName} index={index}>
                                    <Td className="border-l-4" style={{ borderLeftColor: team.teamColor }}>{team.teamName}</Td>
                                    <Td>{team.matchesPlayed}</Td>
                                    <Td>{team.wins}</Td>
                                    <Td>{team.draws}</Td>
                                    <Td>{team.losses}</Td>
                                    <Td>{team.goalsScored}</Td>
                                    <Td>{team.goalsConceded}</Td>
                                    <Td>{team.goalDifference}</Td>
                                    <Td className="font-bold">{team.points}</Td>
                                  </Tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </Container>
  );
};

export default StandingsPage;
