import React, {useContext} from "react";
import { useState } from "react";

import handballImage from "../resources/images/logo_eng.png";
import handballImageESP from "../resources/images/logo_esp.png"
import Button from "../components/Button";
import {useTranslation} from "react-i18next";
import {useSpring, animated} from 'react-spring';
import standingsIcon from '../resources/images/competition.png';
import scoreBorardIcon from '../resources/images/scoreboard.png';
import liveIcon from '../resources/images/live.png';
import matchesIcon from '../resources/images/schedule.png';
import LocaleContext from "../LocaleContext";
import {Link} from 'react-router-dom';


function App() {
    const {t} = useTranslation();
    const {locale} = useContext(LocaleContext);


    // Animation for the text content
    const textFade = useSpring({from: {opacity: 0}, to: {opacity: 1}, delay: 200});

    const TextCard = ({ shortText, fullText }) => {
        const [isExpanded, setIsExpanded] = useState(false);
      
        const toggleExpand = () => {
          setIsExpanded(!isExpanded);
        };
      
        return (
          <div
            className={`relative cursor-pointer rounded-lg overflow-hidden shadow-lg bg-black bg-opacity-20 p-4 ${
              isExpanded ? "h-auto" : "h-32"
            }`}
            onClick={toggleExpand}
          >
            <div className="relative flex flex-col justify-between h-full">
              <p className="text-orange-300 text-lg lg:text-2xl font-semibold">
                {shortText}
              </p>
             
              <button
                className={`relative flex flex-col mt-4 h-8 w-36 text-white px-5 rounded-lg font-semibold bg-transparent backdrop-blur-lg ${
                  isExpanded ? "opacity-0" : "opacity-100"
                }`}
              >
                {t("home.button.read_more")}
              </button>
             
              {isExpanded && ( 
                <div>
                <p className="text-orange-300 text-lg lg:text-2xl font-semibold mt-4">
                  {fullText}
                </p>

                    <button
                    className="relative flex flex-col mt-4 h-8 text-white px-4 rounded-lg font-semibold bg-transparent backdrop-blur-lg" >
                    {t("home.button.read_less")}
                    </button>
                </div>
              )}
            </div>
          </div>
        );
      };

    return (
        <div style={{
            backgroundImage: "url(/fondo1.jpg)",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed'
        }}
             className="bg-none">

            {/* Main Section */}
            <div className="container m-auto flex text-justify rounded-md pt-12 px-6 box-border">

                {/* Text Content with animation */}
                <animated.div className="flex-1 mr-8" style={textFade}>

                    <h1 className="text-2xl lg:text-6xl font-bold mb-4 text-orange-400 px-2">
                        {t("home.title")}
                    </h1>

                    <h2 className="text-lg lg:text-3xl font-medium lg:mb-16 text-orange-400 px-2">
                        {t("home.subtitle")}
                    </h2>

                    <div className="grid lg:grid-cols-2 text-sm lg:text-xl mb-6 font-medium">
                        {/* Text */}
                        <div className="mt-12 space-y-8">
                            <TextCard
                                shortText={t("home.paragraph1.short")}
                                fullText={t("home.paragraph1")}
                            />
                            <TextCard
                                shortText={t("home.paragraph2.short")}
                                fullText={t("home.paragraph2")}
                            />
                            <TextCard
                                shortText={t("home.paragraph3.short")}
                                fullText={t("home.paragraph3")}
                            />
                            <div className="grid grid-flow-col gap-8 w-2/3">
                            <Button
                                to="/signup"
                                className="inline-block font-medium py-2 px-6 rounded transition-colors duration-150 ease-in-out lg:!text-lg"
                                color={"orange"}>
                                {t("home.createAccount")}
                            </Button>
                            <Button
                                to="/login"
                                className="inline-block font-semibold py-2 px-12 rounded transition-colors duration-150 ease-in-out lg:!text-lg !bg-black !bg-opacity-70"
                                color={"grey"}>
                                {t("Login")}
                            </Button>
                            </div>
                        </div>

                        {/* Image */}
                        <animated.div className="hidden lg:block justify-self-end mb-12 ml-12 lg:w-2/3"
                                      style={textFade}>
                            <img
                                src={locale === 'en' ? handballImage : handballImageESP}
                                alt={locale === 'en' ? "English logo" : "Spanish logo"}
                                width={30}
                                className=" w-full"
                            />

                        </animated.div>
                    </div>
                </animated.div>

            </div>
            <div className="bg-background w-full">
                <div
                    className="grid lg:grid-cols-4 grid-cols-1 gap-10 text-center py-8 justify-items-center m-auto px-4 w-2/3">

                    {/* Schedule */}
                    <Link to="/matches">
                        <div
                            className="flex flex-col items-center justify-center transform transition-transform duration-500 ease-in-out lg:hover:scale-110">
                            <img
                            src={matchesIcon}
                            alt="Team Icon"
                            className="w-24 align-self-center"
                            />                            
                            <h3 className="pt-4 text-xl font-semibold text-orange-900">{t("home.schedule.title")}</h3>
                            <p className="pt-2">{t("home.schedule.text")}</p>

                        </div>
                    </Link>

                    <Link to="/matches">
                        {/* live */}
                        <div
                            className="flex flex-col items-center justify-center transform transition-transform duration-500 ease-in-out lg:hover:scale-110">
                            <img src={scoreBorardIcon} alt="Team Icon" className="w-24 align-self-center"/>
                            <h3 className="pt-4 text-xl font-semibold text-orange-900">{t("home.live.title")}</h3>
                            <p className="pt-2">{t("home.live.text")}</p>
                        </div>
                    </Link>

                    {/* Standings */}
                    <Link to="/standings">
                        <div
                            className="flex flex-col items-center justify-center transform transition-transform duration-500 ease-in-out lg:hover:scale-110">
                            <img src={standingsIcon} alt="Team Icon" className="w-24 align-self-center"/>
                            <h3 className="pt-4 text-xl font-semibold  text-orange-900">{t("home.results.title")}</h3>
                            <p className="pt-2">{t("home.results.text")}</p>
                        </div>
                    </Link>

                    {/* Stream */}
                    <Link to="/">
                        <div
                            className="flex flex-col items-center justify-center transform transition-transform duration-500 ease-in-out lg:hover:scale-110">
                            <img src={liveIcon} alt="Team Icon" className="w-24 align-self-center"/>
                            <h3 className="pt-4 text-xl font-semibold  text-orange-900">{t("home.streams.title")}</h3>
                            <p className="pt-2">{t("home.streams.text")}</p>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="bg-background lg:flex items-center justify-center hidden">
                <iframe className="w-2/3 "
                        height='900'
                        src={locale === 'en' ? "https://www.youtube.com/embed/GJEz3sqrsM8 " : "https://www.youtube.com/embed/N5ZEuib9jlI"}>
                </iframe>

            </div>
            <div className="h-40 bg-background">
            </div>
        </div>
    );
}

export default App;
