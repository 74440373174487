import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { Table, Th, Td, Tr } from "../components/Table";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";

/**
 * Renders the Teams Page component.
 */
function TeamsPage() {
  const { t } = useTranslation();

  const MAX_FILE_SIZE_MB = 4;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

  const [teams, setTeams] = useState([]);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false); // New state to track the side panel open/close state
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isModifyUser, setIsModifyUser] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);
  const { username, setUsername } = useContext(AuthContext);
  const { userRoles, setUserRoles } = useContext(AuthContext);
  const [paymentReceiptFile, setPaymentReceiptFile] = useState(null);

  const [filter, setFilter] = useState("");

  const [logoFile, setLogoFile] = useState(null); // New state for logo file
  const fileInputRef = useRef(null);
  const logoInputRef = useRef(null); // Reference for logo file input

  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    fetchTeams();
  }, [token]);

  const fetchTeams = async () => {
    try {
      const response = await axios.get("/api/teams", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

       // Filter teams to only include those managed by the logged-in user
    const userManagedTeams = response.data.filter(
      (team) => team.managerName === username
    );

    setTeams(userManagedTeams);
      console.log("Fetched Teams:", response.data);
    } catch (error) {
      console.error("Failed to fetch teams", error);
    }
  };

  const handleSort = (key) => {
    if (sortBy === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(key);
      setSortOrder("asc");
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleLogoChange = (event) => { // New function to handle logo changes
    const file = event.target.files[0];
    if (file.size > MAX_FILE_SIZE_BYTES) {
      alert(t("alert.file.limit"));
      return;
    }
    setLogoFile(file); // Store the selected logo file in state
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.size > MAX_FILE_SIZE_BYTES) {
      alert(t("alert.file.limit"));
      return;
    }
    setPaymentReceiptFile(file); // Store the selected file in state
  };

  const handleSelectFile = () => {
    fileInputRef.current.click(); // Trigger the file input's click event
  };

  const handleSelectLogo = () => {
    logoInputRef.current.click(); // Trigger the logo input's click event
  };


  const filteredTeams = teams.filter((team) =>
    team.name.toLowerCase().includes(filter.toLowerCase())
  );

  const sortedAndFilteredTeams = filteredTeams.sort((a, b) => {
    if (sortBy) {
      const comparison = a[sortBy].localeCompare(b[sortBy]);
      return sortOrder === "asc" ? comparison : -comparison;
    }
    return 0;
  });

  const deleteTeam = async (teamId) => {
    try {
      const response = await axios.delete(`/api/teams/${teamId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(`Team with ID ${teamId} deleted successfully`);
      fetchTeams();
      // Perform any additional actions after deleting the team
    } catch (error) {
      console.error(`Failed to delete team with ID ${teamId}`, error);
    }
  };

  const uploadLogo = async (teamId) => { // New function to handle logo upload
    if (!logoFile) {
      console.error("No logo file selected");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("logoFile", logoFile);

      const response = await axios.post(
        `/api/teams/${teamId}/logo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Logo uploaded successfully", response.data);
      window.alert(t("logo.uploaded.success"));
      window.location.reload();
    } catch (error) {
      console.error("Failed to upload logo", error);
    }
  };

  const uploadReceipt = async (teamId) => {
    if (!paymentReceiptFile) {
      console.error("No receipt file selected");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("receiptFile", paymentReceiptFile);

      const response = await axios.post(
        `/api/teams/${teamId}/receipt`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Receipt uploaded successfully", response.data);

      window.alert(t("receipt.uploaded.success"));
      window.location.reload();
    } catch (error) {
      console.error("Failed to upload receipt", error);
    }
  };

  const handleTeamClick = async (teamId) => {
    try {
      const response = await axios.get(`/api/teams/${teamId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSelectedTeam(response.data);
      setIsSidePanelOpen(true); // Open the side panel when a team is selected

      console.log(response.data, " -- ", userRoles);
      if (
        username === response.data.managerName ||
        userRoles.includes("ROLE_ADMIN")
      ) {
        setIsModifyUser(true);
      } else {
        setIsModifyUser(false);
      }
    } catch (error) {
      console.error("Failed to fetch team details", error);
    }
  };

  const handleUploadLogo = () => { // New function to handle logo upload button click
    uploadLogo(selectedTeam.id);
  };

  const handleCloseButtonClick = () => {
    setSelectedTeam(null);
    setIsSidePanelOpen(false); // Close the side panel when the close button is clicked
  };

  const handleDeleteButton = () => {
    const confirmed = window.confirm(t("confirm.delete"));

    if (confirmed) {
      setSelectedTeam(null);
      deleteTeam(selectedTeam.id);
      setIsSidePanelOpen(false); // Close the side panel when the close button is clicked
    }
  };

  const handleUploadReceipt = () => {
    uploadReceipt(selectedTeam.id);
  };

  const handleModifyButtonClick = () => {
    setSelectedTeam(null);
    setIsSidePanelOpen(false); // Close the side panel when the close button is clicked
    console.log(selectedTeam);
    navigate("/teamreg", { state: { team: selectedTeam } });
  };

  const handleNewTeamButtonClick = () => {
    setSelectedTeam(null);
    setIsSidePanelOpen(false); // Close the side panel when the close button is clicked
    navigate("/teamreg");
  };

  const renderTeamsTable = () => (
    <div className="z-10">
      <p className="text-2xs lg:text-xs text-gray-500 mb-4">{t("team.click.details")}</p>
      <Table>
        <thead>
          <Tr>
            <Th onClick={() => handleSort("name")}>{t("Name")}</Th>
            <Th onClick={() => handleSort("clubName")}>{t("Club")}</Th>
            <Th onClick={() => handleSort("type")}>{t("Category")}</Th>
            <Th onClick={() => handleSort("gender")}>{t("Gender")}</Th>
            <Th onClick={() => handleSort("status")}>
              {t("Registration status")}
            </Th>
          </Tr>
        </thead>
        <tbody>
          {sortedAndFilteredTeams.map((team, index) => (
            <Tr
              key={team.id}
              onClick={() => handleTeamClick(team.id)}
              index={index}
            >
              <Td>{team.name}</Td>
              <Td>{team.clubName}</Td>
              <Td>{t(team.type)}</Td>
              <Td>{t(team.gender)}</Td>
              <Td>{t(team.status)}</Td>
            </Tr>
          ))}
        </tbody>
      </Table>
      
    </div>
  );

  const renderTeamDetails = () => (
    <div className="container flex flex-col">
      {selectedTeam && (
        <div className="">
          <h2 className="text-lg">{t("Team details")}</h2>

          <div className="flex flex-row gap-4 mt-4 text-sm">
            <div>
              <div className="team-details-group">
                <p>{t("Club Name")}:</p>
                <p>{selectedTeam.clubName}</p>
              </div>
              <div className="team-details-group">
                <p>{t("Team name")}:</p>
                <p>{selectedTeam.name}</p>
              </div>
              <div className="team-details-group">
                <p>{t("Category")}:</p>
                <p>{t(selectedTeam.type)}</p>
              </div>
              <div className="team-details-group">
                <p>{t("Gender")}:</p>
                <p className="team-details-value">{t(selectedTeam.gender)}</p>
              </div>
              <div className="team-details-group">
                <p>{t("Country")}:</p>
                <p>{selectedTeam.address.country}</p>
              </div>
              <div className="team-details-group">
                <p>{t("State")}:</p>
                <p>{selectedTeam.address.state}</p>
              </div>
              <div className="team-details-group">
                <p>{t("City")}:</p>
                <p>{selectedTeam.address.city}</p>
              </div>
              <div className="mt-4 w-60">
                <h3 className="my-4 text-lg">{t("Colors")}</h3>
                <Table>
                  <thead>
                    <Tr>
                      <Th style={{ width: "calc(100% / 3)" }}>
                        {t("Primary")}
                      </Th>
                      <Th style={{ width: "calc(100% / 3)" }}>
                        {t("Secondary")}
                      </Th>
                      <Th style={{ width: "calc(100% / 3)" }}>
                        {t("Goalkeeper")}
                      </Th>
                    </Tr>
                  </thead>
                  <tbody>
                    <Tr>
                      <Td
                        style={{
                          backgroundColor: selectedTeam.color1,
                          width: "calc(100% / 3)",
                        }}
                      ></Td>
                      <Td
                        style={{
                          backgroundColor: selectedTeam.color2,
                          width: "calc(100% / 3)",
                        }}
                      ></Td>
                      <Td
                        style={{
                          backgroundColor: selectedTeam.color3,
                          width: "calc(100% / 3)",
                        }}
                      ></Td>
                    </Tr>
                  </tbody>
                </Table>
              </div>
            </div>
              {/* Logo Upload Section */}
              <div>
                  <p className=" text-lg">{t("Upload logo")}</p>
                  {selectedTeam.logo ? (
                    <>
                      <img
                        className="w-2/3 mx-auto my-4"
                        src={"data:image/jpeg;base64," + selectedTeam.logo}
                        alt="Logo"

                      />

                      <p className=" mt-2 text-xs">{t("logo.uploaded")}</p>
                    </>
                  ) : (
                    <p className=" mt-2 text-xs">{t("logo.required")}</p>
                  )}
                  <input
                    className={`${
                      fileInputRef ? "text-titleText" : "text-transparent"
                    } mt-2 w-2/3 file:hidden  border border-placeholderText cursor-pointer focus:border-transparent  focus:ring-main 
                    block
                    text-sm
                    text-blue-500
                    bg-white bg-clip-padding
                    transition
                    ease-in-out
                    m-0
                    focus:border-main focus:outline-none`}
                    placeholder="test"
                    type="file"
                    ref={logoInputRef}
                    onChange={handleLogoChange}
                    accept=".jpeg,.jpg"
                  />
                  {selectedTeam.logo ? (
                    <Button
                      color="orange"
                      className="mr-4"
                      onClick={handleUploadLogo}
                    >
                      {t("button.replace")}
                    </Button>
                  ) : (
                    <Button
                      color="orange"
                      className="mr-4"
                      onClick={handleUploadLogo}
                    >
                      {t("button.upload")}
                    </Button>
                  )}
                </div>
              </div>

          {/* Render other team details here */}
          <div style={{ display: isModifyUser ? "block" : "none" }}>
            <h3 className="my-4 text-lg">{t("Responsible person")}</h3>
            <Table>
              <thead>
                <Tr>
                  <Th>{t("First name")}</Th>
                  <Th>{t("Last name")}</Th>
                  <Th>{t("Second name")}</Th>
                  <Th>{t("Phone number")}</Th>
                  <Th>{t("Year of birth")}</Th>
                </Tr>
              </thead>
              <tbody>
                <Tr index={0}>
                  <Td>{selectedTeam.responsiblePerson.firstName}</Td>
                  <Td>{selectedTeam.responsiblePerson.lastName}</Td>
                  <Td>{selectedTeam.responsiblePerson.secondName}</Td>
                  <Td>{selectedTeam.responsiblePerson.telephone}</Td>
                  <Td>{selectedTeam.responsiblePerson.yearOfBirth}</Td>
                </Tr>
              </tbody>
            </Table>

            <h3 className="my-4 text-lg">{t("Players")}</h3>
            <Table>
              <thead>
                <Tr>
                  <Th>{t("First name")}</Th>
                  <Th>{t("Last name")}</Th>
                  <Th>{t("Second name")}</Th>
                  <Th>{t("Year of birth")}</Th>
                  <Th>{t("Player number")}</Th>
                </Tr>
              </thead>
              <tbody>
                {selectedTeam.players.map((player, index) => (
                  <Tr key={player.id} index={index}>
                    <Td>{player.person.firstName}</Td>
                    <Td>{player.person.lastName}</Td>
                    <Td>{player.person.secondName}</Td>
                    <Td>{player.person.yearOfBirth}</Td>
                    <Td>{player.number}</Td>
                  </Tr>
                ))}
              </tbody>
            </Table>

            <h3 className="my-4 text-lg">{t("Officials")}</h3>
            <Table>
              <thead>
                <Tr>
                  <Th>{t("First name")}</Th>
                  <Th>{t("Last name")}</Th>
                  <Th>{t("Second name")}</Th>
                  <Th>{t("Year of birth")}</Th>
                </Tr>
              </thead>
              <tbody>
                {selectedTeam.officials.map((player, index) => (
                  <Tr key={player.id} index={index}>
                    <Td>{player.firstName}</Td>
                    <Td>{player.lastName}</Td>
                    <Td>{player.secondName}</Td>
                    <Td>{player.yearOfBirth}</Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </div>

          {isModifyUser && (
            <div className="block mt-4">
              <p className=" text-lg">{t("Upload receipt")}</p>
              {selectedTeam.receipt ? (
                <>
                  <img
                    className="w-2/3 mx-auto my-4"
                    src={"data:image/jpeg;base64," + selectedTeam.receipt}
                    alt="Receipt"

                  />

                  <p className=" mt-2 text-xs">{t("receipt.uploaded")}</p>
                </>
              ) : (
                <p className=" mt-2 text-xs">{t("receipt.required")}</p>
              )}
              <input
                className={`${
                  fileInputRef ? "text-titleText" : "text-transparent"
                } mt-2 w-2/3 file:hidden  border border-placeholderText cursor-pointer focus:border-transparent  focus:ring-main 
                block
                text-sm
                text-blue-500
                bg-white bg-clip-padding
                transition
                ease-in-out
                m-0
                focus:border-main focus:outline-none`}
                placeholder="test"
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept=".jpeg,.jpg"
              />
              {selectedTeam.receipt ? (
                <Button
                  color="orange"
                  className="mr-4"
                  onClick={handleUploadReceipt}
                >
                  {t("button.replace")}
                </Button>
              ) : (
                <Button
                  color="orange"
                  className="mr-4"
                  onClick={handleUploadReceipt}
                >
                  {t("button.upload")}
                </Button>
              )}
            </div>
          )}

          <br />
          <div className="block mt-2">
            {isModifyUser && (
              <>
                <Button
                  color="orange"
                  disabled={!isModifyUser}
                  className="mr-4"
                  onClick={handleModifyButtonClick}
                >
                  {t("Modify")}
                </Button>{" "}
                {/* Edit button */}
                <Button
                  color="orange"
                  disabled={true}
                  className="mr-4"
                  onClick={handleDeleteButton}
                >
                  {t("Delete")}
                </Button>{" "}
                {/* Change button */}
              </>
            )}

            <Button color="orange" onClick={handleCloseButtonClick}>
              {t("Close")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="container mx-auto my-8 p-4 bg-white rounded-md shadow-lg text-justify">
      <h1 className="hidden text-2xl mb-8 leading-10 lg:block">{t("Teams")}</h1>
      {isLoggedIn ? (
        <div className=" gap-5 mt-2 flex flex-col-reverse lg:grid lg:grid-cols-2">
          <div className="">{renderTeamsTable()}</div>
          <h1 className="text-3xl mb-4 leading-10 lg:hidden">{t("Teams")}</h1>
          <div className="">{renderTeamDetails()}</div>
        </div>
      ) : (
        renderTeamsTable()
      )}
    </div>
  );
}

export default TeamsPage;
